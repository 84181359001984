import React from 'react'
import Head from 'next/head'
import { WebsiteLayout } from '@website/components/layout/WebsiteLayout'
import { HeroBasic } from '@ui/hero'
import { Hero404Ant } from '@ui/components/Svg'

const title = 'Page Not Found'
const description = 'The page you are looking for does not exist.'

export default function Custom404() {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Head>
      <WebsiteLayout headerClassName="bg-off-white">
        <div className="bg-off-white flex min-h-screen flex-col items-center justify-center lg:-mt-[112px]">
          <HeroBasic
            title="Page Not Found"
            description="Oops! Looks like you're a little lost. The page you're looking for is not available."
          />
          <div className="flex items-center justify-center pb-20 pt-0 md:pt-20">
            <Hero404Ant />
          </div>
        </div>
      </WebsiteLayout>
    </>
  )
}
